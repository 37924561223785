import { datadogRum } from '@datadog/browser-rum';
import { getEnv } from './env';

const BASE_CONFIG = {
  site: 'datadoghq.com',
  service: 'auth-page',
  env: getEnv().env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackViewsManually: false,
  storeContextsAcrossPages: true,
  defaultPrivacyLevel: 'allow',
  usePartitionedCrossSiteSessionCookie: true,
  compressIntakeRequests: false,
};

const APP_TOKENS = {
  app: {
    clientToken: 'pub043d733ee0f6877efd86e39c82b692aa',
    applicationId: 'cc0265f6-0370-4d05-b0d2-c64468a0a03b',
  },
  hq: {
    clientToken: 'pubd259cb671389bc35700ed75a568c2458',
    applicationId: '7bc8c79f-0308-479b-9499-4018635b06cd',
  },
};

export const initializeDatadog = () => {
  const { dimension, env } = getEnv();
  const config = APP_TOKENS[dimension];

  // TODO: Understand why for HQ the env is set to HQ and not production
  if (env !== 'production' && env !== 'hq') {
    return;
  }

  if (!config) {
    console.error(`Invalid Datadog dimension: ${dimension}`);
    return;
  }

  datadogRum.init({
    ...BASE_CONFIG,
    ...config,
  });
};

export { datadogRum };
