import React from 'react';
import ReactDOM from 'react-dom';
import { initializeDatadog } from 'utils';

import { AppProvider } from 'components';

initializeDatadog();

ReactDOM.render(<AppProvider />, document.getElementById('root'));

// trigger
