import React, { useEffect, useMemo, useRef } from 'react';
import Cookie from 'js-cookie';
import history from '@guestyci/history';
import useFeatureToggle from '@guestyci/feature-toggle-fe/useFeatureToggle';
import { datadogRum } from '../../utils/datadog';

import { useOktaAuth } from '@okta/okta-react';
import * as OktaSignIn from '@okta/okta-signin-widget';
import { blue700 } from '@guestyci/foundation/theme/colors';
import t from '@guestyci/localize/t.macro';

import { isEmpty } from '@guestyci/foundation/utils';

import { utils, okta } from 'utils';

import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';
import guestyLiteLogoSrc from '../assets/guesty-lite-logo.svg';

import {
  FIELDS_ID,
  addSubTitleText,
  addedSubTitleForSMSAuthScreen,
  getFormFieldBlock,
  toggleElement,
  changeMFAButtonsText,
  handleClickContinueButton,
  createErrorTipBlock,
  toggleEmailAndPasswordField,
  removeElement,
  addEyeIconForPasswordField,
  getNode,
  organizeLoginButtonPositions,
  reportErrorToDataDog,
  updateTitle,
  reportActionToDataDog,
  safeDatadogAction,
} from './helpers';

import './Login.css';

import PasswordValidation from '../components/PasswordValidation';
import { handleLogout } from '../logout/helper';
import getOktaTranslations from './oktaLoginTranslations';
const { dimension } = utils.getEnv();

const handleSuccess = ({ token, url }) => {
  utils.localStorage.setToken(token);
  utils.localStorage.setBiData(token);
  window.location.replace(url);
};

const Login = () => {
  const { oktaAuth } = useOktaAuth();

  const {
    return_to: zendeskRedirectUrl,
    ref: urlRef,
    activation: isActivationFlow,
    gl: googleLoginParam,
    package: packageName,
    mailRef: emailParam,
  } = history.getQueryParams();

  const isGoogleLoginEnabled = useMemo(() => {
    return (
      Cookie.get('_guesty_google_login') === 'true' ||
      googleLoginParam === 'true' ||
      googleLoginParam === '1'
    );
  }, [googleLoginParam]);

  const errorTipMessage = t('The email or password is incorrect. Please try again');
  const smsSubTitleScreenMessage = t('We will use the phone number you enter below to send you a verification code via SMS.');
  const emailVerificationCode = t('Get an email with a verification code.');
  const setupEmail = t('Set up Email Authentication');
  const smsVerificationCode = t('Get an SMS with a verification code.');
  const setupSMS = t('Set up SMS Authentication');
  const setupGoogleAuthVerificationCode = t('Use the Google Authenticator app to get a verification code.');
  const setupGoogleAuth = t('Set up Google Authenticator');
  const subTitleText = t('Please enter your details to sign in.');
  const liteWelcomeFlowBackgroundText = t('Manage, automate, and streamline your business, all from one platform!');

  const [isNewWelcomeFlowLoading, isNewWelcomeFlowEnabled] = useFeatureToggle('lite-verified-welcome-flow');

  useSyncSessionStorage('zendeskRedirectUrl', zendeskRedirectUrl);
  useSyncSessionStorage('ref', urlRef);
  useSyncSessionStorage('isActivationFlow', isActivationFlow);

  utils.useDocumentTitle('Login');
  utils.usePageView('login');

  useEffect(() => {
    if (isActivationFlow && packageName === 'lite') {
      datadogRum.clearUser();
      reportActionToDataDog('User email verified');
    }
  }, [isActivationFlow]);

  // Prevent ENTER key press, when the password field is hidden
  useEffect(() => {
    const handleEnterPress = e => {
      const emailField = document.getElementById(FIELDS_ID.email);
      const passwordField = document.getElementById(FIELDS_ID.password);

      const passwordFiledBlock = getFormFieldBlock('password');

      if (e.keyCode === 13) {
        if (passwordFiledBlock && passwordFiledBlock.style.display === 'none') {
          e.preventDefault();
        }

        if (
          passwordField &&
          passwordField.value === '' &&
          passwordFiledBlock.style.display === 'block'
        ) {
          toggleElement('.okta-form-subtitle', false);
        }

        if (
          emailField &&
          emailField.value !== '' &&
          passwordFiledBlock.style.display === 'none'
        ) {
          handleClickContinueButton();
          return false;
        }
      }

      return true;
    };

    window.addEventListener('keypress', handleEnterPress);

    return () => {
      window.removeEventListener('keypress', handleEnterPress);
    };
  }, []);

  useEffect(() => {
    const { s: mfaSecret } = history.getQueryParams();
    if (mfaSecret) {
      utils.api
        .confirmMfa(mfaSecret)
        .then(response => {
          const token = response?.data?.token;
          if (token) {
            handleSuccess({ token, url: '/' });
          }
        })
        .catch(() => {});
    }
  }, []);

  const insertPasswordChecklist = () => {
    const mainInput = getNode('[name="newPassword"]');
    const confirmInput = getNode('[name="confirmPassword"]');
    const confirmField = getNode('.o-form-fieldset:nth-child(2)');
    const validationsContainer = getNode('.password-validations-container');

    confirmField.after(validationsContainer); // insert password checklist component
    toggleElement('.okta-form-subtitle', false); // hide description
    toggleElement('.o-form-fieldset:nth-child(2)', false); // hide confirmation field
    toggleElement('.password-validations-container', true); // show password validation component

    mainInput.addEventListener('input', event => {
      const value = event.target.value;
      confirmInput.value = value;
      confirmInput.dispatchEvent(new Event('change', { bubbles: true })); // trigger Okta validation
    });
  };

  const insertLiteWelcomeFlow = () => {
    const wrapper = getNode('.wrapper');
    const wrapperSide = getNode('.wrapper div');
    const brandingBlock = getNode('.wrapper .iframe-container');
    const backgroundContainer = document.createElement('div');
    const oktaSignIn = getNode('#okta-sign-in');
    const stepsWrapper = document.createElement('div');
    stepsWrapper.classList.add('steps-wrapper');
    const stepFinished = document.createElement('div');
    const stepActive = document.createElement('div');
    const step = document.createElement('div');
    const stepLast = document.createElement('div');
    const guestyLiteLogo = document.createElement('img');
    guestyLiteLogo.src = guestyLiteLogoSrc;
    guestyLiteLogo.alt = 'Guesty Lite Logo';
    const backgroundContainerText = document.createElement('h1');
    backgroundContainerText.classList.add('lite-welcome-flow-background-text');
    backgroundContainerText.textContent = liteWelcomeFlowBackgroundText;

    stepFinished.classList.add('step-finished');
    stepActive.classList.add('step-active');
    step.classList.add('step');
    stepLast.classList.add('step');

    wrapperSide.prepend(guestyLiteLogo);
    stepsWrapper.appendChild(stepFinished);
    stepsWrapper.appendChild(stepActive);
    stepsWrapper.appendChild(step);
    stepsWrapper.appendChild(stepLast);
    wrapperSide.appendChild(stepsWrapper);

    wrapper.classList.add('lite-welcome-flow-wrapper');
    wrapperSide.classList.add('lite-welcome-flow-wrapper-side');
    backgroundContainer.classList.add('lite-welcome-flow-background');
    oktaSignIn.classList.add('lite-okta-sign-in');
    backgroundContainer.appendChild(backgroundContainerText);
    wrapper.appendChild(backgroundContainer);

    brandingBlock.style.display = 'none';
  };

  const addRegistrationBlockForGuestyHostUsers = () => {
    const registrationBlock = getNode('.registration-container');
    const loginBlockGFH = getNode('.guesty-for-host-login');

    if (!loginBlockGFH && registrationBlock) {
      const contentContainer = document.createElement('div');
      contentContainer.classList.add('content-container');
      contentContainer.classList.add('guesty-for-host-login');

      const registrationLabel = document.createElement('span');
      registrationLabel.textContent = t('Guesty For Hosts User? ');
      registrationLabel.classList.add('registration-label');

      const registrationLink = document.createElement('a');
      registrationLink.textContent = t('Login here');
      registrationLink.href = 'https://app.guestyforhosts.com/';

      contentContainer.appendChild(registrationLabel);
      contentContainer.appendChild(registrationLink);

      registrationBlock.prepend(contentContainer);
    }
  };

  const addRegistrationBlock = () => {
    const registrationBlock = getNode('.registration-container');

    if (utils.isHQDomain && registrationBlock) {
      registrationBlock.remove();

      return;
    }

    addRegistrationBlockForGuestyHostUsers();
  };

  const setGoogleLoginToDD = () => {
    try {
      datadogRum.setGlobalContextProperty('login_type', {
        name: 'google',
      });
    } catch (e) {
      console.log('failed to set login type', e);
    }
  };

  const registerWidgetEvents = widget => {
    widget.on('afterError', (_context, error) => {
      console.log('After error called', { error });
      toggleElement('.auth-content', true);

      if (!isEmpty(error)) {
        reportErrorToDataDog(error, {
          errorType: 'okta_widget_error',
          errorLocation: 'Login',
          context: _context,
        });

        toggleElement('.okta-form-subtitle', false);

        if (error?.statusCode === 401) {
          createErrorTipBlock(errorTipMessage);
        }
      }
    });

    widget.after('identify', async () => {
      console.log('Widget identify called');
    });

    widget.on('afterRender', context => {
      console.log('Rendered');
      console.log('After render called', { context });

      if (isGoogleLoginEnabled) {
        organizeLoginButtonPositions();
      } else {
        const el = document.querySelector('#okta-sign-in .auth-divider');
        if (el) {
          el.remove?.();
        }
      }

      addRegistrationBlock();

      addedSubTitleForSMSAuthScreen({ smsSubTitleScreenMessage, setupSMS });
      changeMFAButtonsText([
        {
          tag: 'p',
          text: emailVerificationCode,
          buttonText: setupEmail,
        },
        {
          tag: 'p',
          text: smsVerificationCode,
          buttonText: setupSMS,
        },
        {
          tag: 'p',
          text: setupGoogleAuthVerificationCode,
          buttonText: setupGoogleAuth,
        },
      ]);

      if (context?.controller === 'password-reset') {
        insertPasswordChecklist();
        addEyeIconForPasswordField('[name="newPassword"]');
        if (
          isActivationFlow &&
          packageName === 'lite' &&
          isNewWelcomeFlowEnabled
        ) {
          insertLiteWelcomeFlow();
        }
      }
      if (context?.controller === 'forgot-password') {
        updateTitle(t('Forgot password?'));
      }

      // hide form while loading because of content replacement
      toggleElement(
        '.auth-content',
        context?.controller !== 'recovery-loading'
      );

      if (context?.controller !== 'primary-auth') {
        return;
      }

      toggleEmailAndPasswordField('password', false);
      toggleElement('.auth-footer', false);
      addSubTitleText(subTitleText);

      addEyeIconForPasswordField('#okta-signin-password');

      const errorBlock = getNode('.o-form-error-container');
      const title = getNode('.okta-form-title.o-form-head');

      title.after(errorBlock);

      const submitForm = getNode('#form1');
      submitForm?.addEventListener('submit', () => {
        exitErrorState();
      });
    });
  };

  const processCreds = async (creds, callback) => {
    utils.eventsLogger.unifiedLoginDio.track('input_password', 'click');
    callback();
  };

  const widgetRef = useRef();

  useEffect(() => {
    if (!widgetRef.current || isNewWelcomeFlowLoading) {
      return;
    }

    const widgetConfig = getWidgetConfig(
      processCreds,
      isGoogleLoginEnabled,
      isNewWelcomeFlowEnabled
    );
    const widget = new OktaSignIn(widgetConfig);
    registerWidgetEvents(widget);

    widget.showSignInAndRedirect({
      el: widgetRef.current,
    });

    return () => widget.remove();
  }, [oktaAuth, isNewWelcomeFlowLoading]);

  useEffect(() => {
    const handleLoginWithActiveSession = async () => {
      const session = await oktaAuth.session.get();
      if (session.status === 'ACTIVE') {
        handleLogout(oktaAuth);
      }
    };
    handleLoginWithActiveSession();
  }, [oktaAuth]);

  return (
    <div>
      <div
        className="password-validations-container"
        style={{ display: 'none' }}
      >
        <PasswordValidation />
      </div>
      <div ref={widgetRef} />
    </div>
  );
};

export default Login;

function exitErrorState() {
  removeElement('.error-tip-block');
  removeElement('.error-auth-block');
  removeElement('#error-container');
}

function getUserLanguage() {
  const userLanguage = window.localStorage.getItem('lang');
  if (!userLanguage || userLanguage === 'en-US') {
    return 'en';
  }
  return userLanguage;
}

function sendLiteUserEventDataOnCreatePasswordFlow(
  userEmail,
  isV3WelcomeFlowAvailable
) {
  if (utils.eventsLogger.unifiedLoginDio) {
    utils.eventsLogger.unifiedLoginDio.track('create_password', 'pageview', {
      userEmail,
      isV3WelcomeFlowAvailable,
    });
  }
}

function getWidgetConfig(
  processCreds,
  isGoogleLoginEnabled,
  isNewWelcomeFlowEnabled
) {
  const {
    issuer,
    clientId,
    redirectUri,
    scopes,
    useInteractionCode,
    googleIDPId,
  } = okta.oidc;

  const userLanguage = getUserLanguage();
  const searchParams = new URLSearchParams(window.location.search);
  const recoveryToken = searchParams.get('token');
  const userEmail = searchParams.get('mailRef');

  if (recoveryToken) {
    if (userEmail) {
      sendLiteUserEventDataOnCreatePasswordFlow(
        userEmail,
        isNewWelcomeFlowEnabled
      );
    }

    window.history.replaceState({}, document.title, window.location.pathname);
  }

  const oktaLoginTranslations = getOktaTranslations();

  return {
    baseUrl: issuer.split('/oauth2')[0],
    recoveryToken,
    clientId,
    redirectUri,
    logo: `${process.env.PUBLIC_URL}/guesty-logo-dark-blue.svg`,
    language: userLanguage,
    i18n: { [userLanguage]: oktaLoginTranslations },
    features: { rememberMe: true, registration: true },
    authParams: {
      issuer,
      scopes,
      prompt: 'consent',
    },
    processCreds,
    useInteractionCodeFlow: useInteractionCode, // Set to true, if your org is OIE enabled
    brandName: 'Guesty',
    colors: {
      brand: blue700,
    },
    customButtons: [
      {
        i18nKey: 'customButton.title',
        className: 'button button-primary btn-continue',
        click(e) {
          e.preventDefault();
          handleClickContinueButton();
        },
      },
    ],
    helpLinks: {
      forgotPassword: dimension === 'app' ? '/auth/forgot' : undefined,
      help:
        'https://help.guesty.com/hc/en-gb/articles/9369852308509-Troubleshooting-Login-Issues',
    },
    registration: {
      click() {
        window.location.href = 'https://www.guesty.com/request-a-demo/';
      },
    },
    idps:
      isGoogleLoginEnabled && googleIDPId
        ? [{ type: 'GOOGLE', id: googleIDPId }]
        : undefined,
    prompt: 'consent',
    idpDisplay: 'SECONDARY',
  };
}
/* RELATED LINKS
https://github.com/okta/okta-signin-widget/blob/master/docs/classic.md#openid-connect
https://developer.okta.com/docs/reference/api/oidc/#authorize
* */

function useSyncSessionStorage(key, value) {
  useEffect(() => {
    if (value) {
      window.sessionStorage.setItem(key, value);
    } else {
      window.sessionStorage.removeItem(key);
    }
  }, [key, value]);
}
