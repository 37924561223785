import okta from './okta';

import * as api from './api';
import * as localStorage from './localStorage';
import * as eventsLogger from './eventsLogger';

import useDocumentTitle from './useDocumentTitle';
import usePageView from './usePageView';
import saveUserData from './user';

import { getZendeskRedirectUrl } from './getRedirectUrl';
import { getEnv } from './env';
import { isHQDomain } from './getHQDomain';
import { initializeDatadog } from './datadog';

const utils = {
  api,
  getZendeskRedirectUrl,
  getEnv,
  eventsLogger,
  localStorage,
  useDocumentTitle,
  usePageView,
  saveUserData,
  isHQDomain,
};

export { utils, okta, initializeDatadog };
