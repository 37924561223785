import Col from '@guestyci/foundation/Layout/Col';
import createStyles from '@guestyci/foundation/createStyles';
import t from '@guestyci/localize/t.macro';

const useStyles = createStyles(theme => ({
  root: {
    display: 'flex',
    gap: theme.spacer(2),
    flexWrap: 'wrap',
    marginTop: theme.spacer(2),

    '& > li': {
      width: 195,
      display: 'flex',
      alignItems: 'center',
      color: '#6B7280',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '18px',
      '&::before': {
        content: '""',
        display: 'inline-block',
        width: 2,
        height: 2,
        borderRadius: '50%',
        marginRight: theme.spacer(1),
        backgroundColor: '#6B7280',
      },
    },
  },
}));

const PasswordValidation = () => {
  const classes = useStyles();

  const rules = [
    t('Minimum 8 characters'),
    t('One number'),
    t('One lowercase letter'),
    t('One uppercase letter'),
    t('No email addresses'),
    t('Not your first or last name'),
  ];

  return (
    <Col spacing={1}>
      <ul className={classes.root}>
        {rules.map((rule, idx) => (
          <li key={idx}>{rule}</li>
        ))}
      </ul>
    </Col>
  );
};

export default PasswordValidation;
